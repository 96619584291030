<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card class="opa" style="min-height: 600px">
        <v-card-title>
          <h2 style="width: 100%" class="grey--text pa-2">
            <v-icon>mdi-plus</v-icon>
            Ajouter un établissement
          </h2>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-btn
          @click="saveFiche"
          color="pink"
          dark
          large
          fab
          absolute
          bottom
          right
          fixed
          style="bottom: 5px"
        >
          <v-icon>mdi-sd</v-icon>
        </v-btn>

        <v-card-text>
          <v-spacer class="ma-2 pa-2"></v-spacer>
          <h3 id="account">
            <v-icon class="pa-2">mdi-account-circle</v-icon>Le Manager
          </h3>
          <v-img
            class="float-right"
            width="200"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Amazon_Alexa_logo.svg/2560px-Amazon_Alexa_logo.svg.png"
          />

          <v-card class="pa-3" dark color="primary">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  append-icon="mdi-account-circle"
                  v-model="etb.manager.nom"
                  :rules="[rules.required, rules.min]"
                  label="Nom du Manager"
                  hint="Nom du responsable Manager"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  append-icon="mdi-account-circle"
                  v-model="etb.manager.prenom"
                  :rules="[rules.required, rules.min]"
                  label="Prénom du Manager"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  append-icon="mdi-email"
                  v-model="etb.manager.email"
                  :rules="[rules.required, rules.email]"
                  hint="E-mail de connexion"
                  label="E-mail du Manager"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  type="password"
                  append-icon="mdi-lock"
                  :rules="[rules.required]"
                  v-model="etb.manager.password"
                  label="Mot de passe du Manager"
                  hint="minimum 8 caractères"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  type="password"
                  append-icon="mdi-lock"
                  :rules="[rules.password, rules.repass]"
                  v-model="etb.manager.repassword"
                  label="Reconfirmer le mot de passe"
                  hint="minimum 8 caractères"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <v-divider class="pa-6"></v-divider>
          <v-spacer></v-spacer>

          <h3 id="informations">
            <v-icon class="pa-2">mdi-apps</v-icon>Les Informations
          </h3>

          <v-row cols="12">
            <v-col cols="4">
              <v-text-field
                append-icon="mdi-pencil"
                v-model="etb.infos.name"
                label="Nom de l'établissement"
                :rules="[rules.required, rules.long]"
                hint="Ce nom peut-être lu avec Alexa"
                variant="contained"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-select
                :items="[ { id: 'H', title: 'Hospitality' },  { id: 'SL', title: 'Senior Living' }, ]"
                label="Choix du type d'établissement"
                item-text="title"
              item-value="id"
                v-model="etb.isLiving"
              ></v-select>
            </v-col>

            <v-col cols="4">
              <v-text-field
                append-icon="mdi-source-branch"
                v-model="etb.manager.property"
                hint="Ex: amzn1.alexa.unit.did.AHKHL26AQPMBCOYXKU6MSEZPLBA4AJCVAZHMFMGVLBTJ5IGWXYA5NWZMBYZL7SQOY4CGZS4TJJTGQYOOQPSNWP5V6TY62FJSKFJK5GDR"
                label="Alexa Hospitality Property ID"
                :rules="[rules.required]"
                required
              ></v-text-field>
              <v-btn
                :loading="loading"
                :disabled="!etb.manager.property.length"
                color="secondary"
                @click="loadRooms"
                rounded
              >
                <v-icon class="pa-2">mdi-sync</v-icon> Synchroniser les chambres
                de l'établissement</v-btn
              >
              <v-chip color="primary" label v-if="etb.rooms.length"
                >{{ etb.rooms.length }} chambres chargées</v-chip
              >
            </v-col>
          </v-row>

          <v-row cols="12">
            <v-col cols="6">
              <v-textarea
                append-icon="mdi-pencil"
                v-model="etb.infos.description"
                label="Description de l'établissement"
                :rules="[rules.required, rules.long]"
                required
              ></v-textarea>
            </v-col>

            <v-col cols="6">
              <v-textarea
                append-icon="mdi-pencil"
                v-model="etb.infos.descriptionRoom"
                label="Description de la chambre"
                :rules="[rules.required, rules.long]"
                required
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                append-icon="mdi-city"
                v-model="etb.infos.city"
                label="Ville de l'établissement"
                :rules="[rules.required]"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                append-icon="mdi-map"
                v-model="etb.infos.cp"
                label="CP de l'établissement"
                :rules="[rules.required]"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                append-icon="mdi-marker"
                v-model="etb.infos.address"
                label="Adresse de l'établissement"
                :rules="[rules.required]"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card elevation="2" class="pa-2">
          <v-card-title
            ><v-icon class="ma-1">mdi-timer</v-icon> Horaires d'ouvertures de
            l'établissement</v-card-title
          >

          <v-row>
            <v-col cols="3">
              <v-switch
                label="Lundi"
                class="pa-2"
                v-model="serviceLundi"
              ></v-switch>
              <v-text-field
                cols="2"
                solo
                label="De"
                hint="en heure"
                v-model="etb.horaires.lundi.begin"
                :disabled="!serviceLundi"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
              <v-text-field
                cols="2"
                label="À"
                solo
                hint="en heure"
                v-model="etb.horaires.lundi.end"
                :disabled="!serviceLundi"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-switch
                label="Mardi"
                class="pa-2"
                v-model="serviceMardi"
              ></v-switch>
              <v-text-field
                cols="2"
                label="De"
                solo
                v-model="etb.horaires.mardi.begin"
                :disabled="!serviceMardi"
                prepend-icon="mdi-alarm"
                :rules="hourRules"
              ></v-text-field>
              <v-text-field
                cols="2"
                label="A"
                solo
                v-model="etb.horaires.mardi.end"
                :disabled="!serviceMardi"
                prepend-icon="mdi-alarm"
                :rules="hourRules"
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-switch
                label="Mercredi"
                class="pa-2"
                v-model="serviceMercredi"
              ></v-switch>
              <v-text-field
                cols="2"
                label="De"
                solo
                v-model="etb.horaires.mercredi.begin"
                :disabled="!serviceMercredi"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
              <v-text-field
                cols="2"
                label="À"
                solo
                v-model="etb.horaires.mercredi.end"
                :disabled="!serviceMercredi"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-switch
                label="Jeudi"
                class="pa-2"
                v-model="serviceJeudi"
              ></v-switch>
              <v-text-field
                cols="2"
                label="De"
                solo
                :disabled="!serviceJeudi"
                v-model="etb.horaires.jeudi.begin"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
              <v-text-field
                cols="2"
                label="À"
                solo
                v-model="etb.horaires.jeudi.end"
                :disabled="!serviceJeudi"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-switch
                label="Vendredi"
                class="pa-2"
                v-model="serviceVendredi"
              ></v-switch>
              <v-text-field
                cols="2"
                label="De"
                solo
                :disabled="!serviceVendredi"
                v-model="etb.horaires.vendredi.begin"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
              <v-text-field
                cols="2"
                label="À"
                solo
                :disabled="!serviceVendredi"
                v-model="etb.horaires.vendredi.end"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-switch
                label="Samedi"
                class="pa-2"
                v-model="serviceSamedi"
              ></v-switch>
              <v-text-field
                cols="2"
                label="De"
                solo
                :disabled="!serviceSamedi"
                v-model="etb.horaires.samedi.begin"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
              <v-text-field
                cols="2"
                label="À"
                solo
                v-model="etb.horaires.samedi.end"
                :disabled="!serviceSamedi"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-switch
                label="Dimanche"
                class="pa-2"
                v-model="serviceDimanche"
              ></v-switch>
              <v-text-field
                cols="2"
                label="De"
                solo
                :disabled="!serviceDimanche"
                v-model="etb.horaires.dimanche.begin"
                :rules="hourRules"
                prepend-icon="mdi-alarm"
              ></v-text-field>
              <v-text-field
                cols="2"
                label="À"
                solo
                v-model="etb.horaires.dimanche.end"
                :rules="hourRules"
                :disabled="!serviceDimanche"
                prepend-icon="mdi-alarm"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>

        <v-card-actions class="mt-6">
          <v-btn
            block
            large
            color="primary"
            class="white--text mt-8"
            @click="saveFiche"
          >
            <v-icon>mdi-plus</v-icon>Créer l'établissement
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-snackbar v-model="snackbarAdmin">
      Vous êtes super administrateur!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="snackbarAdmin = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarRooms">
      Vos chambres de l'établissement ont été importées dans le Manager
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbarRooms = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="green accent-4" elevation="24" v-model="snackbar">
      Votre établissement a été crée avec succès !
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      :timeout="3000"
      v-model="snackbarMessage"
      color="red accent-2"
      top
      >{{ messageNotif }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red accent-4"
          text
          v-bind="attrs"
          @click="snackbarMessage = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>


<script>
import xhr from "../plugins/axios";
import moment from "moment";
import { mask } from "vue-the-mask";
import { v4 as uuidv4 } from "uuid";

moment.locale("fr");

const email = /^\S+@\S+\.\S+$/;
const long = /^.{3,}$/;
export default {
  name: "add",
  directives: { mask },
  data() {
    return {
      hourRules: [
          v => !!v || 'Heure est requise',
          v => /([0-1]?[0-9]|2[0-3])h/.test(v) || 'Invalide! Ex: 12h',
          v => parseInt(v) >= 1 &&  parseInt(v) <= 23 || 'Heure invalide',
        ],
      rules: {
        required: (value) => !!value || "Obligatoire.",
        min: (value) => (value && value.length > 3) || "Pas assez long",
        password: (value) => (value && value.length > 7) || "Pas assez long",
        email: (value) => email.test(value) || "E-mail non valide",
        long: (value) => long.test(value) || "Non valide",
        repass: (value) =>
          (value && value.length > 7 && this.etb.manager.password == value) ||
          "Confirmation non valide",
      },
      etb: {
        rooms: [],
        roomsIds: [],
        manager: {
          id: uuidv4(),
          nom: "",
          prenom: "",
          email: "",
          password: "",
          property: "",
          admin: false,
        },
        annonce: "",
        isLiving: "SL",
        menu: {
          lundi: {
            lunch: "",
            afternoon: "",
            diner: "",
            afternoonnext: "",
            dinernext: "",
            lunchnext: "",
          },
          mardi: {
            lunch: ".",
            afternoon: "",
            diner: "",
            lunchnext: "",
            afternoonnext: "",
            dinernext: "",
          },
          mercredi: {
            lunch: "",
            afternoon: "",
            diner: "",
            lunchnext: "",
            afternoonnext: "",
            dinernext: "",
          },
          jeudi: {
            lunch: "",
            afternoon: "",
            diner: "",
            lunchnext: "",
            afternoonnext: "",
            dinernext: "",
          },
          vendredi: {
            lunch: "",
            afternoon: "",
            diner: "",
            lunchnext: "",
            afternoonnext: "",
            dinernext: "",
          },
          samedi: {
            lunch: "",
            afternoon: "",
            diner: "",
            lunchnext: "",
            afternoonnext: "",
            dinernext: "",
          },
          dimanche: {
            lunch: "",
            afternoon: "",
            diner: "",
            lunchnext: "",
            afternoonnext: "",
            dinernext: "",
          },
        },
        horaires: {
          lundi: { begin: null, end: null },
          mardi: { begin: null, end: null },
          mercredi: { begin: null, end: null },
          jeudi: { begin: null, end: null },
          vendredi: { begin: null, end: null },
          samedi: { begin: null, end: null },
          dimanche: { begin: null, end: null },
        },
        restaurant: {
          "petit déjeuner": {
            begin: null,
            end: null,
          },
          déjeuner: {
            begin: null,
            end: null,
          },
          goûter: {
            begin: null,
            end: null,
          },
          dîner: {
            begin: null,
            end: null,
          },
        },
        services: [],
        events: [],
        infos: {
          name: "",
          address: "",
          message: "",
          description: "",
          city: "",
          cp: "",
        },
      },
      dateNow: null,
      loading: false,
      snackbarRooms: false,
      snackbarAdmin: false,
      dateNext: null,
      weekSwitch: "week",
      valueLoad: 0,
      query: false,
      show: true,
      interval: 0,
      typeCal: "week",
      messageNotif: "",
      snackbarMessage: false,
      value: "",
      alpha: false,
      serviceLundi: false,
      serviceMardi: false,
      serviceMercredi: false,
      serviceJeudi: false,
      serviceVendredi: false,
      serviceSamedi: false,
      serviceDimanche: false,
      dialogService: false,
      updateField: false,
      titleService: "",
      descriptionService: "",
      categorieService: "",
      snackbar: false,
      prop: {},
      rooms: [],
      titleEvent: "",
      idEvent: 1,
      colors: [
        "#003e5c",
        "#91be1e",
        "#673AB7",
        "#00BCD4",
        "#4CAF50",
        "#FF9800",
        "#757575",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      selectedEvent: {},
      selectedElement: null,
      dialog: false,
      dialogAdd: false,
      dialogAddTitle: "Ajouter un évenement",
      typeAdd: "animation",
      search: "",
    };
  },
  methods: {
    loadRooms() {
      this.loading = true;
      xhr
        .post("/load-rooms", {
          property: this.etb.manager.property,
        })
        .then(async (res) => {
          this.snackbarRooms = true;
          this.loading = false;
          this.etb.rooms = res.data;
          this.etb.roomsIds = res.data.map((e) => e.id);
          console.log(res.data);
        });
    },
    saveFiche() {
      this.snackbar = true;
      xhr
        .post("/add", {
          manager: this.etb.manager,
          etb: this.etb,
        })
        .then(async (req, res) => {
          this.snackbar = true;
          setTimeout(() => {
            window.location = "/";
          }, 3000);
          // window.location = "/add";
        });
    },
  },
  watch: {
    weekSwitch(val) {
      console.log(val);
    },
  },
  computed: {},
  created() {
    let token = localStorage.getItem("user");
    if (token) {
      token = JSON.parse(localStorage.getItem("user"));
    }

    if (token.admin == true) {
      setTimeout(() => {
        this.snackbarAdmin = true;
      }, 2000);
    }

    if (token.admin == false) {
      return router.push({ name: "Home" });
    }
  },
};
</script>